import { graphql } from 'gatsby'
import React from 'react'
import { Seo } from '../components/seo'
import PageTitle from '../components/pageTitle'
import { Spacer } from '../components/spacer'
import Contact from '../components/contact'

const ContactPage = ({ data: { markdownRemark } }) => {
    const { frontmatter } = markdownRemark

    return (
        <>
            <PageTitle
                title={frontmatter?.PageTitle?.Title}
                text={frontmatter?.PageTitle?.Text}
            />
            <Spacer />
            <Contact />
        </>
    )
}

export default ContactPage

export const Head = ({ location, data: { markdownRemark } }) => {
    const { frontmatter } = markdownRemark
    return (
        <Seo
            title={frontmatter?.metaTitle}
            description={frontmatter?.metaDesc}
            pathname={location?.pathname}
        ></Seo>
    )
}

export const pageQuery = graphql`
    {
        markdownRemark(frontmatter: { id: { eq: "contact" } }) {
            frontmatter {
                metaTitle
                metaDesc
                PageTitle {
                    Title
                    Text
                }
            }
        }
    }
`
